/* Page layouts */
#app_root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.App {
  min-height: auto;
  flex-grow: 1;

}
Footer {
  position: fixed;
  bottom: 0;
}
.body_container {
  width: 100%;
  height: 80%;
  margin-top: 120px;
  display: flex;
  justify-content: center;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  min-width: 100%;
  grid-column-gap: 0;
}
.flex {
  display: flex;
  flex-direction: column;
}

.textContainer {
  background-color: var(--primary-color);
  height: 100%;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}
.paragraph {
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 20px;
  font-size: 1.5em;
}
/* contact page */

#contact_container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact_layout {
  display: flex;
}

@media (max-width: 1000px) {
  .body_container {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;

  }
}

@media screen and (max-width: 1000px) {
  .paragraph {
    font-size: 1.2em;
    padding-top: 0px;
  }
  
}