:root {
  --primary-color-two: #ffdfc5;
  --primary-color-one: #7869ff;

  --white: #ffffff;
  --secondary-color: rgb(241, 241, 241);

  --text-color: #000000;
  --shadow-color: #000000;
}

div {
  transition: background-color 0.5s ease; /* Adjust as needed */
}
