.bold {
  font-weight: 800;
}

.footer_text {
  color: black;
}

h1 {
  font-family: "ibm-plex-sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 3rem;
  padding-left: 20px;
  color: var(--text-color);
}

h2 {
  padding: 10px;
  font-family: "ibm-plex-sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2rem;
  line-height: 3rem;
  margin-left: 5%;
  color: var(--text-color);
}
* {
  font-family: "ibm-plex-sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}
p {
  padding: 10px;
  font-family: "ibm-plex-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 2rem;
  color: var(--text-color);
  margin: 0;
  margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}