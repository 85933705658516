#header {
  position: fixed;
  display: grid;
  grid-template-areas: "ness nav right";
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
  height: 110px;
  border-bottom: 5px solid black;
}
#ness_logo {
  font-size: 25px;
  margin: 0;
  padding: 0;
  color: black;
  position: sticky;
  z-index: 10;
}
#ness_box {
  grid-column: 1;
  grid-area: ness;
  background-color: var(--primary-color-two);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center; 
  align-items: center;
  border-right: 5px solid black;
}

#ness {
  font-size: 25px;
  position: relative;
  left: -20px;
}
#app {
  font-size: 25px;
  margin-top: -20px;
  position: relative;
  left: 50px;
  padding: 0px;
}

#nav_container {
  height: 100%;
  grid-column: 2;
  grid-area: nav;
  border-right: 5px solid black;
  background-color: var(--primary-color-one);
  width: 100%;
  display: flex;
}

.nav {
  align-self: center;
  justify-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav_items {
  display: inline-block;
  margin: 20px;
}

.Toggle {
  height: 50px;
  width: 150px;
  display: flex;
  font-size: 15px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  border: 2.5px solid black;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  font-size: 1.2em;
  font-weight: 1000;
  color: black;
}
/* Other styles */

#colog .Toggle:on,
#colog .Toggle[data-state="on"] {
  background-color: lightblue;
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}
#colog .Toggle:hover,
#colog .Toggle[data-state="on"] {
  background-color: lightblue;
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}

#team .Toggle:on,
#team .Toggle[data-state="on"] {
  background-color: rgb(176, 238, 176);
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}
#team .Toggle:hover,
#team .Toggle[data-state="on"] {
  background-color: rgb(176, 238, 176);
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}

#contact .Toggle:on,
#contact .Toggle[data-state="on"] {
  background-color: rgb(199, 165, 242);
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}
#contact .Toggle:hover,
#contact .Toggle[data-state="on"] {
  background-color: rgb(199, 165, 242);
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}

#home .Toggle:on,
#home .Toggle[data-state="on"] {
  background-color: #ffdfc5;
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}
#home .Toggle:hover,
#home .Toggle[data-state="on"] {
  background-color: #ffdfc5;
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
}

a {
  text-decoration: none;
  display: inline-block; 
}
a:visited {
  color: inherit;
}

#right_box {
  grid-column: 3;
  grid-area: right;
  padding: 10px;
  background-color: var(--primary-color-two);
  width: 100%;
  box-sizing: border-box;
  border-left: 5px solid black; 

}

@media (max-width: 1000px) {
  #header {
    height: 180px; 
    display: flex;
    flex-direction: column;
    border: none; 
    background-color: var(--primary-color-two);

  }
  #ness_box {
    border: none;
    background-color: transparent;
  }
  #ness_logo {
    font-size: 20px; 
  }
  #nav_container {
    bottom: 50px;
    height: 80px;
    background-color: var(--primary-color-one);
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: auto;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    display: flex;
    flex-wrap: wrap;
  }

  .nav_items {
    margin: 10px; 
  }

  .Toggle {
    height: 40px; 
    width: 150px; 
    font-size: 16px;
  }
  #right_box {
    grid-column: 3;
    grid-area: right;
    padding: 0;
    width: 0;
    }
}
@media (max-width: 675px) {
  .nav {
    display: flex;
    justify-content: space-between;
  }
  .nav_items {

    box-sizing: border-box;
    text-align: center;
  }
  .Toggle {
    width: 100px;

  }
}

@media (max-width: 485px) {
  .nav {
    display: flex;
    justify-content: space-between;
  }
  .nav_items {
    text-align: center;
  }
  .Toggle {
    font-size: 12px;
    width: 80px;

  }
}

@media (max-width: 400px) {
  .nav {
    display: flex;
    justify-content: space-between;
  }
  .nav_items {
    text-align: center;
  }
  .Toggle {
    font-size: 12px;
    width: 70px;

  }
}


@media (max-width: 360px) {
  .nav {
    display: flex;
    justify-content: space-between;
  }
  .nav_items {
    text-align: center;
  }
  .Toggle {
    font-size: 12px;
    width: 60px;

  }
}