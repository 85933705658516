#not_found_container {
  display: flex;
  flex-direction: column;
}
#not_found {
  font-size: 100px;
  justify-self: center;
  align-self: center;
}

#cherub {
  height: 200px;
  width: 200px;
  margin-top: 110px;
  position: absolute;
}
