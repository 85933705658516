
#team {
    grid-template-rows: .5fr 1fr;
}
#Alec {
    grid-column: 1;
}
#Will {
    grid-column: 2;
}
.name {
    margin-bottom: 0;
    padding-bottom: 0;
}
.bio {
    margin: 0;
    padding: 0;
    margin-left: 10%;
    font-weight: 800;
}
.linkedin {
    margin: 0;
    padding: 0;
    margin-left: 10%;
    font-weight: 400;
    font-size: 18px;
    text-decoration: none; 
}

.name_container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}
.inner_container {
    width: 250px;    
}

@media screen and (max-width: 1000px) {
    .inner_container {
        width: 180px;    
    }
    
}