.footer {
  background-color: var(--primary-color-one);
  text-align: center;
  width: 100%;
  border-top: 5px solid black;
  display: flex;
  height: 100px;
}

#footer_text {
  display: flex;
}

#right {
  width: 75%;
  background-color: var(--primary-color-two);
  border-left: 5px solid black;
  display: flex;

  justify-items: flex-end;
}

#left {
  width: 25%;
  height: 100%;
  background-color: var(--primary-color-one);
}

#company {
  bottom: 0;
}

#linkedin {
  right: 0;
}

#email {
  right: 150px;
}

@media screen and (max-width: 1000px) {
  .footer {
    height: 50px;
  } 
}