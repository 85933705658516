input {
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  border: 2px solid black;
  width: 50vw;
}
#form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
#message {
  border: 2px solid black;
  height: 20vh;
  resize: none;
  padding: 0.5rem;
  font-size: 1rem;
  width: 50vw;
}

#submit {
  background-color: black;
  color: var(--secondary-color);
  border: none;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  align-self: center;
  width: 150px;
  border: 2px solid black;
}
#submit {
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

#submit:hover {
  background-color: white;
  color: black;
}
body .toast-success {
  background-color: rgb(37, 246, 37);
  border-radius: 0;
  color: #000000;
  font-weight: bold;
  border: 2px solid black;
}

body .toast-error {
  background-color: red;
  border-radius: 0;
  font-weight: bold;
  border: 2px solid black;
}

body .toast_body {
  color: #000000;
}
